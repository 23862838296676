import React from 'react';
// import { Link } from 'gatsby';
import {
	Columns,
	Column,
	Container,
	Footer,
	Level,
	Left,
	// Item,
	Right,
	Menu,
	MenuItem,
	Section
} from '../components/bulma';

import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';
import twitter from '../img/social/twitter.svg';
import linkedin from '../img/social/linkedin.svg';

// import appStore from '../img/AppStore.svg';
import googlePlay from '../img/GooglePlay.svg';
import finhub from '../img/finhub-logo.svg';

export default () => {
	return (
		<Footer>
			<Container>
				<div className="notification">
					<Columns>
						<Column>
							<Menu label="Unmerchant">
								<MenuItem to="/sell-payment-services">Sell payment services</MenuItem>
								<MenuItem to="/pricing">Pricing</MenuItem>
								{/* <MenuItem to="/case-studies">Case Studies</MenuItem> */}
								{/* <MenuItem to="/developers">Developers</MenuItem> */}
								{/* <MenuItem to="/blog">Blog</MenuItem> */}
								<MenuItem to="/get-started">Getting Started</MenuItem>
								{/* <MenuItem to="/digest">Payments Digest</MenuItem> */}
							</Menu>
						</Column>
						<Column>
							<Menu label="Platform">
								<MenuItem to="/platform/business-dashboard">Business Dashboard</MenuItem>
								<MenuItem to="/platform/online-payment-processing">Online Payments</MenuItem>
								<MenuItem to="/platform/point-of-sale-payments">Point of Sale</MenuItem>
								<MenuItem to="/platform/subscription-payments">Subscription Payments</MenuItem>
								<MenuItem to="/platform/smart-invoices">Smart Invoices</MenuItem>
								{/* <MenuItem to="/platform/customer-profiles">Customer Profiles</MenuItem> */}
								{/* <MenuItem to="/platform/mobile-payments">Mobile Apps</MenuItem> */}
							</Menu>
						</Column>

						<Column>
							<Menu label="Solutions">
								<MenuItem to="/solutions/iso">ISOs</MenuItem>
								<MenuItem to="/solutions/banks-credit-unions">Banks &amp; Credit Unions</MenuItem>
								<MenuItem to="/solutions/payment-processors">Payment Processors</MenuItem>
								<MenuItem to="/solutions/software-developers">Software Developers</MenuItem>
								<MenuItem to="/solutions/marketing-agencies">Marketing Agencies</MenuItem>
							</Menu>
						</Column>
						{/* <Column>
							<Menu label="Resources">
								<MenuItem to="/blog">Blog</MenuItem>
								<MenuItem to="/developers">Developers</MenuItem>
								<MenuItem to="/solutions/white-label">White-label</MenuItem>
								<MenuItem to="/faq">FAQ</MenuItem>
								<MenuItem to="/digest">Payments Digest</MenuItem>
								<MenuItem to="/apps">Download Apps</MenuItem>
							</Menu>
						</Column> */}
						{/* <Column>
						<Menu label="Company">
							<MenuItem to="/about">About</MenuItem>
							<MenuItem to="/mission">Mission</MenuItem>
							<MenuItem to="/news">News</MenuItem>
							<MenuItem to="/contact">Contact</MenuItem>
						</Menu>
					</Column> */}
					</Columns>
				</div>

				<Section>
					{/* <Level>
						<Left>
							<p>
								<a title="facebook" href="https://www.facebook.com/paymentninja/">
									<img
										src={facebook}
										alt="Facebook"
										style={{ width: '2em', height: '2em', marginRight: 15 }}
									/>
								</a>
								<a title="twitter" href="https://twitter.com/PaymentNinja">
									<img
										src={twitter}
										alt="Twitter"
										style={{ width: '2em', height: '2em', marginRight: 15 }}
									/>
								</a>
								<a title="linkedin" href="https://www.linkedin.com/company/payment-ninja/">
									<img
										src={linkedin}
										alt="Twitter"
										style={{ width: '2em', height: '2em', marginRight: 15 }}
									/>
								</a>
								<a title="instagram" href="https://www.instagram.com/payment.ninja/">
									<img src={instagram} alt="Instagram" style={{ width: '2em', height: '2em' }} />
								</a>
							</p>
						</Left>
						<Item>
						<Link className="navbar-item" to="/terms">
							Terms of service
						</Link>
						<Link className="navbar-item" to="/privacy">
							Privacy
						</Link>
					</Item>
						<Right>
							<p>
								<a title="App Store" href="https://apps.apple.com/us/app/cashmark/id1459957436">
								<img src={appStore} alt="App Store" />
							</a>{' '}
								<a
									title="Google Play"
									href="https://play.google.com/store/apps/details?id=ninja.payment.merchant"
								>
									<img className="fas fa-lg" src={googlePlay} alt="Google Play" />
								</a>
							</p>
						</Right>
					</Level> */}

					<Level>
						<Left>
							<p className="has-text-left is-size-7">
								<strong>© 2020 Unmerchant</strong>. A{' '}
								<a title="FinHub Global Ventures" href="https://finhub.vc/" target="_blanc">
									FinHub Global Ventures
								</a>{' '}
								company.
								<br />All rights reserved. All product names, trademarks and registered trademarks are
								property of their respective owners.
								<br />All company, product and service names used in this website are for identification
								purposes only.
								<br />Use of these names, trademarks and brands does not imply endorsement.
								<br />Software development by{' '}
								<a title="Fastlabs" href="https://fastlabs.co/" target="_blanc">
									Fastlabs
								</a>. Website by{' '}
								<a title="Fastlabs" href="https://superfast.page/" target="_blanc">
									Superfast
								</a>.
							</p>
						</Left>
						<Right>
							<div
								style={{
									width: '120px',
									display: 'inline-block'
								}}
							>
								<a title="FinHub Global Ventures" href="https://finhub.vc/" target="_blanc">
									<img src={finhub} alt="FinHub Global Ventures logo" />
								</a>
							</div>
						</Right>
					</Level>
				</Section>
			</Container>
			<br />
			<br />
		</Footer>
	);
};
